const DownLoad = () => import('../components/DownLoad')
const HomeIndex = () => import('../components/HomeIndex')
const DownLoadbcfans = () => import('../components/DownLoadbcfans')
export const routes = [
	// {
	// 	path: '/',
	// 	name: 'DownLoad',
	// 	component: DownLoad
	// },
	// {
	// 	path: '/HomeIndex',
	// 	name: 'HomeIndex',
	// 	component: HomeIndex
	// }
	
	
	{
		path: '/',
		name: 'DownLoad',
		component: DownLoad
	},
	{
		path: '/DownLoad',
		name: 'DownLoad',
		component: DownLoad
	}
]
